<template>
    <DataTable lazy paginator scrollable :tableClass="(puzzleStats? 'puzzle-stats' : 'p-datatable-sm')" :rows="contestStatsLimit"
        :value="allUserStats" @page="onPage($event)" :totalRecords="totalStats" :loading="loading" >
        <Column field="ranking" header="Rank" class="ranking-column" />
        <Column header="Name" class="username-column">
            <template #body="slotProps">
                <span class="username-label" @click='goToAnalystProfile(slotProps.data.analyst)'
                    v-tooltip.bottom="'@' + getContestantName(slotProps.data.analyst)">
                    @{{ getContestantName(slotProps.data.analyst) }}
                </span>
            </template>
        </Column>
        <Column class="streak-column">
            <template #header>
                <div :class="isColumnSelected(sortStatIds.streak) ? 'column-header-selected' : 'column-header'"
                    @click="sortOrder(sortStatIds.streak)" v-tooltip.bottom="miniVersion ? 'Streak' : undefined">
                    <span>{{ miniVersion ? 'S' : 'Streak' }}</span>
                    <span class="column-icon pi pi-fw pi-sort-amount-down"></span>
                </div>
            </template>
            <template #body="slotProps">
                {{ slotProps.data.streak }}d
            </template>
        </Column>
        <!-- <Column field="numAnswered" header="#Answered" /> -->
         
         <Column field="numAnswered" class="num-correct-column" v-if="puzzleStats">
            <template #header>
                <div :class="isColumnSelected(sortStatIds.numAnswered) ? 'column-header-selected' : 'column-header'"
                    @click="sortOrder(sortStatIds.numAnswered)" v-tooltip.bottom="miniVersion ? '#Found' : undefined">
                    <span>{{ (miniVersion ? '#F' : '#Found')  }}</span>
                    <span class="column-icon pi pi-fw pi-sort-amount-down"></span>
                </div>
            </template>
        </Column>
        <Column field="numCorrect" class="num-correct-column">
            <template #header>
                <div :class="isColumnSelected(sortStatIds.numCorrect) ? 'column-header-selected' : 'column-header'"
                    @click="sortOrder(sortStatIds.numCorrect)" v-tooltip.bottom="miniVersion ? '#Correct' : undefined">
                    <span>{{ puzzleStats ? (miniVersion ? '#S' : '#Solved') : (miniVersion ? '#C' : '#Correct') }}</span>
                    <span class="column-icon pi pi-fw pi-sort-amount-down"></span>
                </div>
            </template>
        </Column>
        <Column class="rewards-column">
            <template #header>
                <div :class="isColumnSelected(sortStatIds.amountRewarded) ? 'column-header-selected' : 'column-header'"
                    @click="sortOrder(sortStatIds.amountRewarded)" v-tooltip.bottom="miniVersion ? '$Rewards' : undefined">
                    <span>{{ miniVersion ? '$R' : '$Rewards' }}</span>
                    <span class="column-icon pi pi-fw pi-sort-amount-down"></span>
                </div>
            </template>
            <template #body="slotProps">
                {{ formatter.format(slotProps.data.amountRewarded, '$0.00') }}
            </template>
        </Column>
    </DataTable>
</template>

<script>
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

import router from '../../../router';
import FormattingUtils from '../../../utilities/FormattingUtils';

const sortStatIds = {
    cumulativeReturn: 1,
    streak: 2,
    numAnswered: 3,
    numCorrect: 4,
    amountRewarded: 5,
};

export default {
    name: 'ContestStatsTable',
    emits: ['change-sort-order', 'load-page'],
    props: {
        miniVersion: {
            type: Boolean,
            required: false,
            default: false
        },
        contestStatsLimit: {
            type: Number,
            required: true
        },
        selectedSortStatId: {
            type: Number,
            required: true
        },
        totalStats: {
            type: Number,
            required: true
        },
        allUserStats: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        puzzleStats: {
            type: Boolean,
            required: false
        },
    },
    components: {
        DataTable,
        Column
    },
    data() {
        return {
            formatter: new FormattingUtils(),
            sortStatIds,
        }
    },

    methods: {
        isColumnSelected(sortStatId) {
            return this.selectedSortStatId == sortStatId;
        },

        onPage(event) {
            const offset = event.page * event.rows;
            console.log(event, offset);
            this.$emit('load-page', offset);
        },

        sortOrder(sortStatId) {
            this.$emit('change-sort-order', sortStatId);
        },

        goToAnalystProfile(analyst) {
            if( !this.analystDeleted(analyst)){
                router.push('/analysts/profile/' + analyst.name);
            }
        },

        analystDeleted(analyst) {
            return analyst.name.endsWith("-"+analyst.analystId);
        },

        getContestantName(analyst) {
            if( this.analystDeleted(analyst)){
                return "xxxxxx";
            }
            else {
                return analyst.name;
            }
        }
    }
}
</script>

<style scoped>

::v-deep(.puzzle-stats) {
    width: 100% !important;
}
::v-deep(table th),
::v-deep(table td) {
    padding: 0.4rem 0.4rem !important;
}

::v-deep(.ranking-column) {
    width: 15% !important;
    max-width: 15% !important;
}

::v-deep(.username-column) {
    width: 25% !important;
    max-width: 25% !important;
}

::v-deep(.streak-column),
::v-deep(.num-correct-column) {
    width: 20% !important;
    max-width: 20% !important;
}

::v-deep(.rewards-column) {
    width: 20% !important;
    max-width: 20% !important;
}

.username-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.username-label:hover {
    color: #33CC99;
    cursor: pointer;
}

.column-header:hover {
    color: #33CC99;
    cursor: pointer;
}

.column-header-selected {
    color: #33CC99;
}

.column-icon {
    margin-left: 2px;
}

::v-deep(.p-paginator .p-paginator-pages .p-paginator-page) {
    color: #32364e;
}
::v-deep(.p-paginator-first:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-prev:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-next:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-last:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover) {
    border-color: #32364e;
    background: #32364e;
    color: #FFFFFF;
}
::v-deep(.p-paginator-first:focus),
::v-deep(.p-paginator-prev:focus),
::v-deep(.p-paginator-next:focus),
::v-deep(.p-paginator-last:focus),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:focus) {
    box-shadow: none;
}
</style>