<template>
    <LoadingSpinner :showLoading="contestsDetailsLoading" v-if="contestsDetailsLoading" />
    <div v-else-if="miniVersion">
        <!-- <p>Cycle: {{ cycle.label }}</p> -->
        <div v-if="userStats && userStats.length > 0">
            <span><b>Your Stats: </b></span>
            <span><b>
                    Rank:{{ userStats[0].ranking }} Streak:{{ userStats[0].streak }} {{(puzzleStats?"#F:":"#A:")+ userStats[0].numAnswered
                    }} {{(puzzleStats?"#S:":"#C:")+ userStats[0].numCorrect }} $R:{{ userStats[0].amountRewarded }}
                </b></span>
        </div>
        <ContestStatsTable :puzzleStats="puzzleStats" miniVersion v-if="contestStats?.allUserStats && contestStats.allUserStats.length > 0"
            :loading="contestStatsTableLoading" @load-page="loadPage" :allUserStats="contestStats.allUserStats"
            :selectedSortStatId="selectedSortStatId" :contestStatsLimit="contestStatsLimit"
            :totalStats="contestStats.totalStats" @change-sort-order="changeSortOrder" />
    </div>
    <div v-else>
        <div :class="selectedGroup ? '' : 'card'">
            <div class="description-row">
                <div>
                    <template v-if="!selectedGroup">
                        <p>Description: {{ contest.shortDescription }}</p>
                        <p>Cycle: {{ cycle.label }}</p>
                    </template>
                    <template v-if="isEducationalContext">
                        <p>
                            {{"Total #Lessons: " +contestStats.totalPosts }}
                        </p>
                        <p>
                            
                            {{"Total #Questions: " +contestStats.totalQuestionsAsked }}

                        </p>
                    </template>
                    <template v-else>
                        <p v-if="isPuzzle()">{{"Total #Puzzles (so far): "+ contestStats.totalPuzzles }}</p>
                        <p v-else>{{"Total #Questions (so far): "+ contestStats.totalQuestionsAsked }}</p>
                    </template>
                    <template v-if="!selectedGroup">
                        <p>Total Available Rewards (so far):
                            <span v-html="getTotalAvailableRewards"></span>
                        </p>
                    </template>

                    <template v-if="userStats && userStats.length > 0 && userStats[0].analyst">
                        <p>{{selectedGroup ? "Your Progress:" :"Your Stats:"}}</p>

                        <DataTable scrollable :value="userStats" tableClass="puzzle-stats">
                            <Column field="ranking" header="Rank" />
                            <Column header="Name" class="username-column">
                                <template #body="slotProps">
                                    <span class="username-label" @click='goToAnalystProfile(slotProps.data.analyst.name)'
                                        v-tooltip.bottom="'@' + slotProps.data.analyst.name">
                                        @{{ slotProps?.data?.analyst?.name }}
                                    </span>
                                </template>
                            </Column>
                            <Column header="Streak">
                                <template #body="slotProps">
                                    {{ slotProps.data.streak }}d
                                </template>
                            </Column>
                            <Column field="numAnswered" :header='puzzleStats?"#Found":"#Answered"'/>
                            <Column field="numCorrect" :header='puzzleStats?"#Solved":"#Correct"' />
                            <Column header="$Rewards">
                                <template #body="slotProps">
                                    {{ formatter.format(slotProps.data.amountRewarded, '$0.00') }}
                                </template>
                            </Column>
                        </DataTable>
                    </template>
                </div>
                <img class="fintrivia-icon" src="assets/images/fintrivia.png" v-if="!isEducationalContext"/>
            </div>
            
        </div>

        <div :class="selectedGroup ? '' : 'card'" v-if="contestStats.allUserStats && contestStats.allUserStats.length > 0">
            <p>{{selectedGroup ? "Class Progress:" :"Current Stats:"}}</p>

            <ContestStatsTable :puzzleStats="puzzleStats" :loading="contestStatsTableLoading" @load-page="loadPage"
                :allUserStats="contestStats.allUserStats" :selectedSortStatId="selectedSortStatId"
                :contestStatsLimit="contestStatsLimit" :totalStats="contestStats.totalStats"
                @change-sort-order="changeSortOrder" />
        </div>
    </div>
</template>

<script>
import ContestStatsTable from '../components/ContestStatsTable.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import LoadingSpinner from '../../../components/common/LoadingSpinner.vue';

import router from '../../../router';
import { CANADIAN_CURRENCY_TYPE,GROUP_CONTEST_CATEGORIES } from '../../../common/constants';
import FormattingUtils from '../../../utilities/FormattingUtils';
import GroupService from '../../../service/GroupService';
import EventBus from '../../../event-bus';
import BrowserUtils from '../../../utilities/BrowserUtils';
// import {mapState} from 'vuex';
import { hasPremiumGroupSubscription} from '../../groups';

const sortStatIds = {
    cumulativeReturn: 1,
    streak: 2,
    numAnswered: 3,
    numCorrect: 4,
    amountRewarded: 5,
};

export default {
    name: 'ContestStats',
    props: {
        miniVersion: {
            type: Boolean,
            required: false,
            default: false
        },
        contest: {
            type: Object,
            required: true
        },
        cycle: {
            type: Object,
            required: true
        },
        latestGroupStats: {
            type: Boolean,
            required: false,
            default: false
        },
        selectedGroup: {
            type: Object,
            required: false
        },
         selectedCourse: {
            type: Object,
            required: false
        },
        puzzleStats: {
            type: Boolean,
            required: false
        },
    },
    components: {
        ContestStatsTable,
        DataTable,
        Column,
        LoadingSpinner,
    },
    data() {
        return {
            formatter: new FormattingUtils(),

            userStats: null,
            contestsDetailsLoading: true,
            contestStatsTableLoading: false,
            contestStats: null,
            contestStatsLimit: BrowserUtils.isMobile() || this.selectedGroup ? 8 : 5,
            contestStatsOffset: 0,
            selectedSortStatId: sortStatIds.amountRewarded
        }
    },

    watch: {
        cycle() {
            this.refresh();
        }
    },

    mounted() {
        // console.log("ContestStats mounted");
        this.refresh();
        this.subscribeToEvents();
    },

    activated() {
        // console.log("ContestStats activated");
        this.refresh();
        this.subscribeToEvents();
    },

    computed: {
        getTotalAvailableRewards() {
            if (this.contest.currencyId == CANADIAN_CURRENCY_TYPE.ID) {
                return this.formatter.format(this.contestStats.totalCADRewardsPossible, '$0.00') + '  <b>CAD Playdough</b>';
            } else {
                return this.formatter.format(this.contestStats.totalUSDRewardsPossible, '$0.00') + ' <b>USD Playdough</b>';
            }
        },

        isEducationalContext() {
            return (this.selectedGroup?.coursesEnabled && (this.selectedGroup?.educational || hasPremiumGroupSubscription(this.selectedGroup?.subscriptionId) )) 
        },
    },

    methods: {

        isPuzzle() {
            return this.contest.categoryId === GROUP_CONTEST_CATEGORIES.puzzle;
        },

        goToAnalystProfile(username) {
            router.push('/analysts/profile/' + username);
        },

        subscribeToEvents() {
            EventBus.off('post-answered');
            EventBus.on('post-answered', activity => {
                if(activity?.contest?.contestId == this.contest?.contestId) {
                    this.refresh();
                }
            });
        },

        refresh() {
            this.contestStatsOffset = 0;
            this.fetchContestStats();
        },

        loadPage(offset) {
            this.contestStatsOffset = offset;
            this.fetchContestStats();
        },

        changeSortOrder(sortStatId) {
            this.selectedSortStatId = sortStatId;
            this.fetchContestStats();
        },

        fetchContestStats() {
            this.contestStatsTableLoading = true;

            const onResponse = resp => {
                if (resp.data.status == "success") {
                    // console.log("getContestStats", resp);
                    this.contestStats = resp.data.stats;

                    this.userStats = this.contestStats?.userStats ? [this.contestStats?.userStats] : null;

                    if (this.miniVersion && this.contestsDetailsLoading && (!this.contestStats.allUserStats || this.contestStats.allUserStats.length == 0)) {
                        EventBus.emit('view-performance');
                    }
                }

                this.contestStatsTableLoading = false;
                this.contestsDetailsLoading = false;
            };

            if(this.latestGroupStats){
                GroupService.getGroupContestStats(this.contest.groupId, this.cycle.value.startDate, this.cycle.value.endDate, 
                    this.contestStatsLimit, this.contestStatsOffset, this.selectedSortStatId).then(onResponse);
            } else {
                if( this.selectedCourse ) {
                     GroupService.getGroupCourseStats(this.selectedGroup.groupId, this.selectedCourse.courseId, this.cycle.value.startDate, this.cycle.value.endDate, 
                        this.contestStatsLimit, this.contestStatsOffset, this.selectedSortStatId).then(onResponse);
                }
                else {
                    GroupService.getContestStats(this.contest.contestId, this.cycle.value.startDate, this.cycle.value.endDate, 
                        this.contestStatsLimit, this.contestStatsOffset, this.selectedSortStatId).then(onResponse);
                }
            }
        },

    }
}
</script>

<style scoped>

::v-deep(.puzzle-stats) {
    width: 100% !important;
}
.card {
    margin-bottom: 1rem;
}

.card .description-row {
    display: flex;
    align-items: center;
}
.card .description-row .fintrivia-icon {
    width: 175px;
    margin-left: auto;
}

::v-deep(table th),
::v-deep(table td) {
    padding: 0.4rem 0.4rem !important;
}

::v-deep(.username-column) {
    width: 16.666%;
    max-width: 16.666%;
    overflow: hidden;
}

::v-deep(.p-datatable-sm) {
    margin-top: 8px;
}

.username-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.username-label:hover {
    color: #33CC99;
    cursor: pointer;
}
</style>