<template>
    <LoadingSpinner :showLoading="contestsLoading" v-if="contestsLoading" />

    <div class="content-cycle-container">
        <table>
            <tr v-if="homePage">
                <th><span>Contest:</span></th>
                <th>
                    <Dropdown :options="contests" optionLabel="name" v-model="selectedContest" />
                </th>
            </tr>
            <tr>
                <th><span>Cycle:</span></th>
                <th>
                    <Dropdown :options="cycleOptions" optionLabel="label" v-model="selectedCycleOption" />
                </th>
            </tr>
        </table>
    </div>

    <ContestStats v-if="selectedCycleOption && selectedContest" :miniVersion="isMobile || homePage" :selectedGroup="selectedGroup" 
        :contest="selectedContest" :cycle="selectedCycleOption" :latestGroupStats="isLatestFeedSelected" :selectedCourse="selectedCourse"/>
</template>

<script>
import ContestStats from '../components/ContestStats.vue';
import LoadingSpinner from '../../common/LoadingSpinner.vue';
import Dropdown from 'primevue/dropdown';

import GroupService from '../../../service/GroupService';
import { getContestCycles } from '../index';
import BrowserUtils from '../../../utilities/BrowserUtils';

const statisticsAndQuestionsLabels = {
    statistics: 'Statistics',
    questions: 'Questions'
}

export default {
    name: 'ContestDetails',
    props: {
        selectedGroup: {
            type: Object,
            required: false
        },
        selectedFeed: {
            type: Object,
            required: false
        },
        homePage: {
            type: Boolean,
            required: false,
            default: false
        },
        selectedCourse: {
            type: Object,
            required: false
        },
    },
    components: {
        ContestStats,
        LoadingSpinner,
        Dropdown
    },
    data() {
        return {
            contestsLoading: true,
            contests: [],
            selectedContest: null,

            contestsMenuItems: [],
            feedMenuItems: [
                { label: 'Questions', action: () => console.log("hi"), selected: true },
            ],

            selectedCycleOption: {},
            cycleOptions: [],

            statisticsAndQuestionsActiveIndex: 0,
            statisticsAndQuestionsItems: [
                { index: 0, label: statisticsAndQuestionsLabels.statistics, selected: true },
                { index: 1, label: statisticsAndQuestionsLabels.questions, selected: false },
            ],
        }
    },

    watch: {
        selectedFeed() {
            this.fetchContests();
        },
         selectedCourse() {
            this.fetchContests();
        },
    },

    computed: {
        isMobile() {
            return BrowserUtils.isMobile();
        },

        isLatestFeedSelected() {
            return this.selectedFeed?.groupFeedId == 0;
        },

        contestFeedData() {
            if (this.selectedCycleOption) {
                return {
                    "contestId": this.selectedContest?.contestId,
                    "groupId": this.selectedContest?.groupId,
                    "cycleStartDate": this.selectedCycleOption?.value?.startDate,
                };
            } else {
                return null;
            }
        },
    },

    mounted() {

        this.fetchContests();

    },

    methods: {

        viewStatisticsAndQuestions(name) {
            this.statisticsAndQuestionsActiveIndex = statisticsAndQuestionsLabels.statistics === name ? 0 : 1;
        },


        initCycles() {
            if( this.selectedContest?.startDate ){
                this.cycleOptions = getContestCycles(this.selectedContest.startDate);

                this.selectedCycleOption = this.cycleOptions[this.cycleOptions.length - 1];
            }
            // console.log("initCycles", this.selectedCycleOption, this.contests);
        },

        selectContest(contest) {
            this.selectedContest = contest;

            this.initCycles();
        },

        fetchContests() {
            this.contestsLoading = true;

            const onResponse = (resp) => {
                this.contestsLoading = false;
                if (resp.data.status == "success") {

                    if(resp.data?.contest){
                        this.contests = [resp.data.contest];
                    } else {
                        this.contests = resp.data.contests;
                    }

                    this.contestsMenuItems = [];

                    this.contests.forEach(contest => {
                        this.contestsMenuItems.push({
                            label: contest.name,
                            action: () => this.selectContest(contest),
                            selected: this.contestsMenuItems.length === 0
                        });
                    });

                    this.selectContest(this.contests[0]);
                }
            };

            if (this.selectedGroup) {
                // console.log(" this.selectedFeed", this.selectedFeed);
                if (!this.isLatestFeedSelected) {
                    if( this.selectedFeed ) {
                      
                        GroupService.getGroupFeedContest(this.selectedFeed.groupFeedId).then(onResponse);
                    }
                    else if( this.selectedCourse){
                       
                        GroupService.getGroupCourseContests(this.selectedGroup.groupId, this.selectedCourse.courseId).then(onResponse);// todo
                    }
                } else {
                    GroupService.getGroupContests(this.selectedGroup.groupId).then(onResponse);
                }
            } else {
                GroupService.getSystemContests().then(onResponse);
            }
        },

    }
};
</script>

<style>
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #32364e !important;
    color: white !important;
}
</style>

<style scoped>
*:not(.pi),
::v-deep(.p-component) {
    font-family: "Trebuchet MS", "Verdana";
}

::v-deep(.scroll-to-top-button) {
    right: 18vw;
}

::v-deep(.p-dropdown) {
    border: 2px solid #32364E;
    border-radius: 50px;
    padding: 2px;
}

::v-deep(.p-dropdown:hover, .p-dropdown:active, .p-dropdown:focus, .p-dropdown.p-focus, .p-inputwrapper-focus) {
    border-color: #32364e;
}

::v-deep(.p-dropdown-label) {
    color: #32364e;
    font-size: 1rem;
    padding: 2px 0px 2px 4px;
}

::v-deep(.p-dropdown-trigger) {
    width: 1.5rem;
}

::v-deep(.p-dropdown-trigger-icon) {
    color: #32364e;
    font-size: 0.9rem;
}

.p-dropdown:not(.p-disabled).p-focus,
::v-deep(.p-dropdown:not(.p-disabled):focus) {
    outline: none !important;
    box-shadow: none !important;
    border-color: unset;
}

.content-cycle-container {
    margin-bottom: 8px !important;
    text-align: left;
}

@media (max-width: 760px) {

    .content-cycle-container {
        margin-bottom: 8px;
    }

    .main-menu-bar {
        margin: 1rem 1rem 1rem 1rem;
    }

    .card {
        border-radius: 0;
        margin-bottom: 2px;
        padding: 0 16px;
    }

    ::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem button) {
        background: #ffffff;
        border: solid #ffffff;
        border-width: 0 0 2px 0;
        padding: 6px;
    }

    ::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight button) {
        background: #ffffff;
        color: #33CC99;
        border: solid #33CC99;
        border-width: 0 0 2px 0;
        padding: 6px;
    }

    ::v-deep(.p-tabmenu-nav) {
        text-align: center;
        border-width: 0;
        display: flex;
        justify-content: space-around;
    }

    ::v-deep(.p-tabmenuitem) {
        padding-top: 0 !important;
    }
}
</style>